<template>
  <div>
    <template>
      <div>
        <!-- 没有授权或者没有绑定手机号 -->
        <van-popup
          v-model="localShow"
          position="bottom"
          custom-style="height: 50%;"
          :close-on-click-overlay="false"
          :closeable="true"
          @click-close-icon="onClose"
        >
          <div class="Box">
            <!-- <div class="boxTop"> -->
            <div class="tit">
              {{ parkName ? parkName : '停车缴费' }}
              {{ showType === 2 ? '申请' : '' }}
            </div>
            <div v-if="showType === 2" :class="['otherTit',showType === 2?'leftClass':'centerClass']">
              绑定您的手机号 以为您提供更多服务
            </div>
            <div v-if="showType === 1" class="otherTit">
              <div>您还未登录，</div>
              <div>
                授权登录后方可进行更多操作
                <van-icon name="warning-o" color="#DA3231" />
              </div>
            </div>
            <van-divider v-if="showType === 2" />
            <div class="content" v-if="showType === 2">
              <!-- 未授权 -->
              <!-- <div > -->
              <!-- <div > -->
              <!-- 您还未登录，授权登录后方可进行更多操作 <van-icon name="warning-o" color="#DA3231" /> -->
              <!-- </div> -->
              <!-- 未绑定手机号 -->
              <div v-if="showType === 2">
                <!-- <div > -->
                <!-- 您还未绑定手机号，绑定手机号后方可进行更多操作 <van-icon name="warning-o"  color="#DA3231"/> -->
                <!-- <div class="content"> -->

                <van-field
                  v-model="form.phone"
                  center
                  clearable
                  label=""
                  type="number"
                  placeholder="请输入手机号"
                >
                </van-field>

                <!-- </div> -->
              </div>
            </div>
            <van-divider v-if="showType === 2" />
            <!-- </div> -->
            <div class="btns">
              <van-button
                class="confirmlBtn"
                type="primary"
                color="##4470FC"
                :loading="btnLoading"
                loading-type="spinner"
                loading-text="请稍后..."
                @click="goAuthOrPhone"
              >
                {{ showType === 1 ? '授权登录' : '绑定' }}
              </van-button>
            </div>
          </div>
        </van-popup>
      </div>
    </template>
  </div>
</template>
<script>
import { Toast } from 'vant'
// import { mapState } from 'vuex'
import * as util from '../api/util'
import * as constant from '../api/constant'

export default {
  name: 'BindPhone',
  props: ['show', 'showType', 'parkName'],
  data () {
    return {
      localShow: this.show,
      form: {
        phone: '',
        phoneCode: ''
      },
      btnLoading: false

    }
  },
  mounted () {

  },
  computed: {
    // ...mapState({
    //   parkName: state => state.parkName
    // })

  },
  watch: {
    show (newVal) {
      // 监听 prop 的变化，并将其赋值给本地的 data 属性
      this.localShow = newVal
    }
  },
  methods: {
    cancelBtn () {
      this.$emit('get-cancel', 2)
    },
    confirmlBtn () {
      this.$emit('get-success', 1)
    },
    onClose () {
      this.$emit('get-on-close')
    },
    senPhoneCode () {
      if (!this.form.phone) {
        Toast.fail('请输入手机号')
        return
      }
      if (!util.isValidPhoneNumber(this.form.phone)) {
        Toast.fail('请输入正确的11位手机号')
        return
      }
      // 测试验证码
      const phoneCode = '123'
      this.form.phoneCode = phoneCode
    },
    goAuthOrPhone (phone, code) {
      if (this.showType === 1) {
        // 跳转到登录页
        this.$router.push('/login')
      } else {
        // 打开绑定手机弹窗
        // this.$emit('showBindPhone')
        this.bindPhone()
      }
    },
    bindPhone (phone, code) {
      if (!this.form.phone) {
        this.$toast('请输入手机号')
        return
      }
      if (!util.isValidPhoneNumber(this.form.phone)) {
        this.$toast('请输入正确的11位手机号')
        return
      }
      this.btnLoading = true
      // if (this.checkOldPhoneLocal) {
      //   // 先验证原手机号
      //   setTimeout(() => {
      //     const code = true
      //     console.log('验证原手机号成功', code)
      //     if (code) {
      //       this.$emit('get-on-check-old')
      //       this.btnLoading = false
      //     } else {
      //       this.Toast('验证原手机号失败')
      //     }
      //   }, 1000)
      // } else {
      // 直接绑定
      console.log('正在绑定手机号', this.form)
      setTimeout(() => {
        // 绑定成功 保存手机号
        this.$store.commit(constant.keyPhoneVuex, this.form.phone)
        Toast.success('手机号码绑定成功')
        // this.$emit('get-on-close')
        this.onClose()
        this.btnLoading = false
      }, 2000)
      // }
    }

  }
}

</script>
<style lang="scss" scoped>
.Box {
  padding: 0.32rem 0.16rem;
  height: 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.tit {
  color: #000;
  font-size: 0.18rem;
  margin-bottom: 0.16rem;
  margin-top: 0.05rem;
  text-align: left;
  padding: 0 0.12rem;
}

.otherTit {
  color: #000;
  font-size: 0.14rem;
  line-height: .2667rem;
  margin: 0.16rem 0 0.32rem;
  padding: 0 0.12rem;
}
.centerClass{
 text-align: center;
}
.leftClass{
 text-align: left;
}
.content {
  color: #000;
  font-size: 0.14rem;
  line-height: 0.2rem;
  // margin: 0 0 0.16rem;
}

.btns {
  /* margin-bottom: 80rpx; */
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 0.16rem;
}

.btns .btn {
  // margin-top: 0.16rem;
  /* margin-bottom: 80rpx; */
  /* margin-left: 50rpx; */
}

.cancelBtn,
.confirmlBtn {
  width: 3.52rem;
  height: 0.4rem;
  /* border: 1rpx solid; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.16rem;
}

.confirmlBtn {
  // background-color: #58BE6A;
  // color: #fff;
  // font-size: 0.9375rem;
  // border: 1px solid #58BE6A;
}

.cancelBtn {
  background-color: #fff;
  color: #4470fc;
  font-size: 0.16rem;
  border: 0.01rem solid #4470fc;
}
.van-divider {
  margin: 0.0533rem 0 !important;
}
</style>
